import { Component, h } from 'preact';
import * as React from 'react';

import HotelAutocomplete from './Components/HotelAutocomplete';
import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';

class HotelForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  onAutocompleteChange(field, country) {
    const newData = { ...this.props.formData };
    newData[field] = country;
    this.props.onChange(newData);
  }

  render() {
    return (
      <div className="box">
        <BoxTitle
          title="Offset a Hotel Booking"
          onRemove={this.props.onRemove}
        />
        <div className="columns">
          <LabelledInput label="Country">
            <HotelAutocomplete
              onInputChange={(country) =>
                this.onAutocompleteChange('location', country)
              }
              algoliaClient={this.props.algoliaClient}
              placeholder="Country"
              countries={this.props.countries}
            />
          </LabelledInput>

          <LabelledInput label="Nights">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                min="1"
                value={this.props.formData.nights}
                onChange={(e) => this.onInputChange('nights', e)}
                placeholder="Nights"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-moon"></i>
              </span>
            </div>
          </LabelledInput>
          <LabelledInput label="Rooms">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                value={this.props.formData.rooms}
                min="1"
                onChange={(e) => this.onInputChange('rooms', e)}
                placeholder="Rooms"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-bed"></i>
              </span>
            </div>
          </LabelledInput>
        </div>
      </div>
    );
  }
}

export default HotelForm;
