import { Component, h } from 'preact';
import * as React from 'react';

/**
 * @var {label} props.label
 * @var {children} props.children
 */
class LabelledInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="column">
        <div className="field">
          <label className="label">{this.props.label}</label>
          <div className="control">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default LabelledInput;
