import { Component, h } from 'preact';
import * as React from 'react';

class Controls extends React.Component {
  constructor(props) {
    super(props);
  }

  carbonButton() {
    if (window.location.host === 'offset.harman.com') {
      // HARMAN do not want the raw CO2 button appearing
      return;
    }

    return (
      <button
        type="button"
        className="button is-success is-light mb-2 ml-2"
        onClick={() => this.props.addForm('carbon')}
      >
        <span>Add CO₂</span>
        <span className="icon">
          <i className="fas fa-plus-circle"></i>
        </span>
      </button>
    );
  }

  render() {
    return (
      <div className="has-text-right">
        <button
          type="button"
          className="button is-success is-light mb-2 ml-2"
          onClick={() => this.props.addForm('meeting')}
        >
          <span>Add Meeting</span>
          <span className="icon">
            <i className="fas fa-plus-circle"></i>
          </span>
        </button>

        <button
          type="button"
          className="button is-success is-light mb-2 ml-2"
          onClick={() => this.props.addForm('taxi')}
        >
          <span>Add Taxi</span>
          <span className="icon">
            <i className="fas fa-plus-circle"></i>
          </span>
        </button>

        <button
          type="button"
          className="button is-success is-light mb-2 ml-2"
          onClick={() => this.props.addForm('car')}
        >
          <span>Add Car</span>
          <span className="icon">
            <i className="fas fa-plus-circle"></i>
          </span>
        </button>

        <button
          type="button"
          className="button is-success is-light mb-2 ml-2"
          onClick={() => this.props.addForm('hotel')}
        >
          <span>Add Hotel</span>
          <span className="icon">
            <i className="fas fa-plus-circle"></i>
          </span>
        </button>

        {this.carbonButton()}

        <button
          type="button"
          className="button is-success is-light mb-2 ml-2"
          onClick={() => this.props.addForm('air')}
        >
          <span>Add Flight</span>
          <span className="icon">
            <i className="fas fa-plus-circle"></i>
          </span>
        </button>
        <button type="submit" className="button is-success mb-2 ml-2">
          <span>Calculate Impact</span>
          <span className="icon">
            <i className="fas fa-arrow-circle-right"></i>
          </span>
        </button>
      </div>
    );
  }
}

export default Controls;
