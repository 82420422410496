import { Component, h } from 'preact';
import * as React from 'react';
import algoliasearch from 'algoliasearch';

import Controls from './Controls';
import AirForm from './Form/AirForm';
import CarbonForm from './Form/CarbonForm';
import HotelForm from './Form/HotelForm';
import CarForm from './Form/CarForm';
import TaxiForm from './Form/TaxiForm';
import MeetingForm from './Form/MeetingForm';
import ErrorForm from './Form/ErrorForm';

class Shop extends React.Component {
  constructor(props) {
    super(props);

    // Handle where metadata is not valid json
    try {
      this.metadata = JSON.parse(props.metadata);
    } catch (e) {
      if (props.metadata) {
        console.warn('Metadata passed is not valid json');
      }
      this.metadata = null;
    }

    this.algoliaClient = algoliasearch(props.algoliaId, props.algoliaSearchKey);

    this.countries = JSON.parse(props.countries);
    this.aircraft = JSON.parse(props.aircraft);

    this.state = {
      formData: [this._newAirState()],
      formErrors: []
    };
  }

  updateFormDataState(key, value) {
    const newState = { ...this.state };
    newState.formData[key] = value;
    this.setState(newState);
  }

  removeSegment(key) {
    const newState = { ...this.state };
    newState.formData.splice(key, 1);
    this.setState(newState);
  }

  onSubmit(e) {
    e.preventDefault();
    const url = new URL(window.location.origin + '/shop/offsets');

    history.replaceState(this.state, '');
    const validationFields = this.getValidationFields();
    const validate = this.validate;

    const segments = [];
    let errors = [];

    this.state.formData.forEach((form) => {
      errors = errors.concat(validate(form, validationFields[form.type]));
      const segment = { ...form };
      switch (segment.type) {
        case 'air': {
          const locations = [segment.origin, segment.destination];
          if (segment.oneway === 'return') {
            locations.push(segment.origin);
          }
          segment.locations = locations;
          delete segment.origin;
          delete segment.destination;
          break;
        }
        case 'hotel': {
          segment.locations = [segment.location];
          delete segment.location;
          break;
        }
        case 'car': {
          if (segment.measurement === 'miles') {
            segment.distance = segment.distance * 1.609344;
          }
          delete segment.measurement;
          break;
        }
        case 'taxi': {
          if (segment.measurement === 'miles') {
            segment.distance = segment.distance * 1.609344;
          }
          delete segment.measurement;
          break;
        }
      }
      segments.push(segment);
    });

    if (errors.length === 0) {
      url.searchParams.append('segments', JSON.stringify(segments));
      if (this.metadata) {
        url.searchParams.append('metadata', JSON.stringify(this.metadata));
      }
      window.location = url;
    } else {
      this.setState({
        ...this.state,
        formErrors: errors
      });
      window.scrollTo(0, 0);
    }
  }

  addForm(formName) {
    const newState = { ...this.state };

    switch (formName) {
      case 'carbon':
        newState.formData.push(this._newCarbonState());
        break;

      case 'air':
        newState.formData.push(this._newAirState());
        break;

      case 'hotel':
        newState.formData.push(this._newHotelState());
        break;

      case 'car':
        newState.formData.push(this._newCarState());
        break;

      case 'taxi':
        newState.formData.push(this._newTaxiState());
        break;

      case 'meeting':
        newState.formData.push(this._newMeetingState());
        break;
    }

    this.setState(newState);
  }

  render() {
    const showRemoveSegment = this.state.formData.length > 1;
    const forms = this.state.formData.map((form, i) => {
      switch (form.type) {
        case 'air':
          return (
            <AirForm
              algoliaClient={this.algoliaClient}
              aircraft={this.aircraft}
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment
                  ? () => this.removeSegment('air', i)
                  : undefined
              }
              segmentIdentifier={i}
            />
          );
        case 'car':
          return (
            <CarForm
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment ? () => this.removeSegment(i) : undefined
              }
            />
          );
        case 'taxi':
          return (
            <TaxiForm
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment ? () => this.removeSegment(i) : undefined
              }
            />
          );
        case 'hotel':
          return (
            <HotelForm
              countries={this.countries}
              algoliaClient={this.algoliaClient}
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment ? () => this.removeSegment(i) : undefined
              }
            />
          );
        case 'meeting':
          return (
            <MeetingForm
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment ? () => this.removeSegment(i) : undefined
              }
            />
          );
        case 'carbon':
          return (
            <CarbonForm
              formData={form}
              onChange={(newVal) => this.updateFormDataState(i, newVal)}
              onRemove={
                showRemoveSegment ? () => this.removeSegment(i) : undefined
              }
            />
          );
      }
    });

    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        {this.state.formErrors.length > 0 && (
          <ErrorForm errors={this.state.formErrors} />
        )}
        {forms}
        <Controls addForm={(formName) => this.addForm(formName)} />
      </form>
    );
  }

  _newAirState() {
    return {
      type: 'air',
      origin: '',
      destination: '',
      passengers: 1,
      travel_class: 'economy',
      oneway: 'return'
    };
  }

  _newCarbonState() {
    return {
      type: 'carbon',
      quantity: ''
    };
  }

  _newHotelState() {
    return {
      type: 'hotel',
      rooms: 1,
      nights: 1,
      location: ''
    };
  }

  _newCarState() {
    return {
      type: 'car',
      distance: 100,
      car_type: 'AVERAGE_CAR',
      measurement: 'km'
    };
  }

  _newTaxiState() {
    return {
      type: 'taxi',
      distance: 30,
      taxi_type: 'TAXI',
      passengers: 1,
      measurement: 'km'
    };
  }

  _newMeetingState() {
    return {
      type: 'meeting',
      hours: 2,
      laptop_count: 2,
      size: 20
    };
  }

  getValidationFields() {
    return {
      air: [
        {
          key: 'origin',
          validate: (value) => !!value,
          message:
            'Please add your departure airport. (You can remove flights if you are not entering flight information).'
        },
        {
          key: 'destination',
          validate: (value) => !!value,
          message: 'Please add your destination airport'
        },
        {
          key: 'passengers',
          validate: (value) => value > 0,
          message: 'Please add at least one passenger'
        }
      ],
      carbon: [
        {
          key: 'quantity',
          validate: (value) => value > 0,
          message: 'Please add some CO₂ to offset'
        }
      ],
      hotel: [
        {
          key: 'location',
          validate: (value) => !!value,
          message: 'Please enter your hotel location'
        },
        {
          key: 'nights',
          validate: (value) => value > 0,
          message: 'Please enter at least one hotel night'
        },
        {
          key: 'rooms',
          validate: (value) => value > 0,
          message: 'Please enter at least one hotel room'
        }
      ],
      car: [],
      taxi: [],
      meeting: [
        {
          key: 'laptop_count',
          validate: (value) => !!value || value === 0,
          message: 'Please enter how many laptops were in the meeting'
        }
      ]
    };
  }

  validate(formData, validation) {
    const errors = [];
    validation.forEach((field) => {
      if (!field.validate(formData[field.key])) {
        errors.push(field.message);
      }
    });
    return errors;
  }
}

export default Shop;
