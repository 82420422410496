import { Component, h } from 'preact';
import * as React from 'react';

import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';

class CarbonForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  render() {
    return (
      <div className="box">
        <BoxTitle title="Offset Carbon" onRemove={this.props.onRemove} />
        <div className="columns">
          <LabelledInput label="How many tonnes of CO₂?">
            <input
              className="input"
              type="number"
              value={this.props.formData.quantity}
              min="0.001"
              step="0.001"
              placeholder="Tonnes of CO₂"
              onChange={(e) => this.onInputChange('quantity', e)}
            />
          </LabelledInput>
        </div>
      </div>
    );
  }
}

export default CarbonForm;
