import { Component, h } from 'preact';
import * as React from 'react';

import BoxTitle from './Components/BoxTitle';

class ErrorForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="box message is-warning">
        <BoxTitle title="Errors" />
        <ul>
          {this.props.errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ErrorForm;
