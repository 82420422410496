import { Component, createRef, Fragment, h } from 'preact';
import * as React from 'react';
import { autocomplete } from '@algolia/autocomplete-js';

class AircraftAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefOrigin = React.createRef();
    this.search = null;
    this.aircraft = props.aircraft;
  }

  onInputChange(country) {
    this.props.onInputChange(country);
  }

  getItemString(item) {
    return `${item.value}`;
  }

  componentDidMount() {
    this.search = autocomplete({
      detachedMediaQuery: 'none',
      container: this.inputRefOrigin.current,
      placeholder: this.props.placeholder,
      getSources: ({ query }) => {
        if (!query) {
          return [];
        }

        return [
          {
            sourceId: 'aircraft',
            getItems: () =>
              this.aircraft
                .filter(
                  (aircraft) =>
                    aircraft.value &&
                    aircraft.value.toLowerCase().includes(query.toLowerCase())
                )
                .slice(0, 5),
            getItemInputValue: ({ item }) => {
              this.onInputChange(item.key);
              return this.getItemString(item);
            },
            onSelect: (item) => {
              this.closeAutocomplete();
              return item;
            },
            templates: {
              header() {
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">Aircraft</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              item: ({ item }) => {
                return (
                  <>
                    <div className="aa-ItemLink">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemTitle">
                          {this.getItemString(item)}
                        </div>
                      </div>
                    </div>
                  </>
                );
              },
              noResults() {
                return <div className="aa-ItemContent">No aircraft found.</div>;
              }
            }
          }
        ];
      }
    });
  }

  closeAutocomplete() {
    setTimeout(() => {
      this.search.setIsOpen(false);
      this.search.update();
    });
  }

  render() {
    return (
      <form>
        <div ref={this.inputRefOrigin} />
      </form>
    );
  }
}

export default AircraftAutocomplete;
