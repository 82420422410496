import { Component, h } from 'preact';
import * as React from 'react';

import RemoveSegment from './RemoveSegment';

/**
 * @var {title} props.title
 * @var {onRemove} props.onRemove
 */
class BoxTitle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="is-flex box-title is-right is-fullwidth is-justify-content-space-between">
        <h2>{this.props.title}</h2>
        <RemoveSegment onRemove={this.props.onRemove} />
      </div>
    );
  }
}

export default BoxTitle;
