import { Component, h } from 'preact';
import * as React from 'react';

/**
 * @var {onRemove} props.onRemove
 */
class RemoveSegment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.onRemove) return null;
    return (
      <a className="icon remove-segment-icon" onClick={this.props.onRemove}>
        <i className="fas fa-times is-size-4"></i>
      </a>
    );
  }
}

export default RemoveSegment;
