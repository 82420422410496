import { Component, createRef, Fragment, h } from 'preact';
import * as React from 'react';
import { autocomplete } from '@algolia/autocomplete-js';

/**
 * @var {algoliaclient} props.algoliaClient
 */
class HotelAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefOrigin = React.createRef();
    this.search = null;
    this.countries = props.countries;
  }

  onInputChange(country) {
    this.props.onInputChange(country);
  }

  getItemString(item) {
    return `${item.value} (${item.key})`;
  }

  componentDidMount() {
    this.search = autocomplete({
      detachedMediaQuery: 'none',
      container: this.inputRefOrigin.current,
      placeholder: this.props.placeholder,
      getSources: ({ query }) => {
        if (!query) {
          return [];
        }

        return [
          {
            sourceId: 'hotels',
            getItems: () =>
              this.countries
                .filter(
                  (country) =>
                    country.value &&
                    country.value.toLowerCase().includes(query.toLowerCase())
                )
                .slice(0, 5),
            getItemInputValue: ({ item }) => {
              this.onInputChange(item.key);
              return this.getItemString(item);
            },
            onSelect: (item) => {
              this.closeAutocomplete();
              return item;
            },
            templates: {
              header() {
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">Hotels</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              item: ({ item }) => {
                return (
                  <>
                    <div className="aa-ItemLink">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemTitle">
                          {this.getItemString(item)}
                        </div>
                      </div>
                    </div>
                  </>
                );
              },
              noResults() {
                return (
                  <div className="aa-ItemContent">No countries found.</div>
                );
              }
            }
          }
        ];
      }
    });
  }

  closeAutocomplete() {
    setTimeout(() => {
      this.search.setIsOpen(false);
      this.search.update();
    });
  }

  render() {
    return (
      <form>
        <div ref={this.inputRefOrigin} />
      </form>
    );
  }
}

export default HotelAutocomplete;
