import { Component, createRef, h } from 'preact';
import * as React from 'react';

import AirportAutocomplete from './Components/AirportAutocomplete';
import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';
import AircraftAutocomplete from './Components/AircraftAutocomplete';

class AirForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefOrigin = React.createRef();
    this.state = {
      optionalFieldsExpanded: false
    };
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  onAutocompleteChange(field, iataCode) {
    const newData = { ...this.props.formData };
    newData[field] = iataCode;
    this.props.onChange(newData);
  }

  displayOptionalSection() {
    var element = document.getElementById(
      `optional-information-air-container-${this.props.segmentIdentifier}`
    );
    element.classList.remove('is-hidden');
    var _element = document.getElementById('expand-air');
    _element.classList.add('is-hidden');
  }

  render() {
    return (
      <div className="box">
        <BoxTitle title="Offset a Flight" onRemove={this.props.onRemove} />

        <div className="columns">
          <LabelledInput label="Departure airport">
            <AirportAutocomplete
              onInputChange={(iataCode) => {
                this.onAutocompleteChange('origin', iataCode);
              }}
              algoliaClient={this.props.algoliaClient}
              placeholder="Departure airport"
            />
          </LabelledInput>

          <LabelledInput label="Destination airport">
            <AirportAutocomplete
              onInputChange={(iataCode) => {
                this.onAutocompleteChange('destination', iataCode);
              }}
              algoliaClient={this.props.algoliaClient}
              placeholder="Destination airport"
            />
          </LabelledInput>

          <LabelledInput label="Travel class">
            <div className="select">
              <select
                value={this.props.formData.travel_class}
                onChange={(e) => this.onInputChange('travel_class', e)}
              >
                <option value="economy" selected>
                  Economy
                </option>
                <option value="premium">Premium economy</option>
                <option value="business">Business</option>
                <option value="first">First</option>
              </select>
            </div>
          </LabelledInput>

          <LabelledInput label="Passengers">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                value={this.props.formData.passengers}
                min="1"
                onChange={(e) => this.onInputChange('passengers', e)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user-friends"></i>
              </span>
            </div>
          </LabelledInput>

          <LabelledInput label="Trip">
            <div className="select" style={{ width: '100%' }}>
              <select
                value={this.props.formData.oneway}
                onChange={(e) => this.onInputChange('oneway', e)}
              >
                <option value="oneway" selected>
                  One way
                </option>
                <option value="return">Round-trip</option>
              </select>
            </div>
          </LabelledInput>
        </div>
        <div
          className="columns is-hidden optional-information-air-container"
          id={`optional-information-air-container-${this.props.segmentIdentifier}`}
        >
          <LabelledInput label="Flight number (optional)">
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                value={this.props.formData.flightNumber}
                placeholder="Flight number"
                onChange={(e) => this.onInputChange('flight_number', e)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-plane"></i>
              </span>
            </div>
          </LabelledInput>

          <LabelledInput label="Airline (optional)">
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                value={this.props.formData.flightNumber}
                placeholder="Airline code (eg AA)"
                maxLength="2"
                onChange={(e) => this.onInputChange('carrier', e)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-plane"></i>
              </span>
            </div>
          </LabelledInput>

          <LabelledInput label="Aircraft type (optional)">
            <AircraftAutocomplete
              aircraft={this.props.aircraft}
              onInputChange={(aircraftType) => {
                this.onAutocompleteChange('aircraft_type', aircraftType);
              }}
              algoliaClient={this.props.algoliaClient}
              placeholder="Aircraft type"
            />
          </LabelledInput>
        </div>
        <div
          className="has-text-right expand-button"
          id="expand-air"
          onClick={() => this.displayOptionalSection()}
        >
          <a className="is-italic">+ Add flight number/aircraft type/airline</a>
        </div>
      </div>
    );
  }
}

export default AirForm;
