import * as React from 'react';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';

/**
 * @var {algoliaclient} props.algoliaClient
 */
class AirportAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefOrigin = React.createRef();
    this.search = null;
  }

  onInputChange(iataCode) {
    this.props.onInputChange(iataCode);
  }

  getItemString(item) {
    return `${item.name} (${item.iata}) - ${item.city}`;
  }

  componentDidMount() {
    this.search = autocomplete({
      detachedMediaQuery: 'none',
      container: this.inputRefOrigin.current,
      placeholder: this.props.placeholder,
      getSources: ({ query }) => {
        if (!query) {
          return [];
        }

        return [
          {
            sourceId: 'airports',
            getItems: () => {
              const algoliaHits = getAlgoliaResults({
                searchClient: this.props.algoliaClient,
                queries: [
                  {
                    indexName: 'airports',
                    query,
                    params: {
                      hitsPerPage: 5
                    }
                  }
                ]
              });
              return algoliaHits;
            },
            getItemInputValue: ({ item }) => {
              this.onInputChange(item.iata);
              return this.getItemString(item);
            },
            onSelect: (item) => {
              this.closeAutocomplete();
              return item;
            },
            templates: {
              header() {
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">Airports</span>
                    <div className="aa-SourceHeaderLine" />
                  </>
                );
              },
              item: ({ item }) => {
                return (
                  <>
                    <div className="aa-ItemLink">
                      <div className="aa-ItemContent">
                        <div className="aa-ItemTitle">
                          {this.getItemString(item)}
                        </div>
                      </div>
                    </div>
                  </>
                );
              },
              noResults() {
                return <div className="aa-ItemContent">No airports found.</div>;
              }
            }
          }
        ];
      }
    });
  }

  closeAutocomplete() {
    // console.log('close autocomplete');
    setTimeout(() => {
      this.search.setIsOpen(false);
      // this.search.refresh();
      this.search.update();
      // console.log('closed autocomplete', this.search);
    });
  }

  render() {
    return (
      <form>
        <div ref={this.inputRefOrigin} />
      </form>
    );
  }
}

export default AirportAutocomplete;
