import { Component, h } from 'preact';
import * as React from 'react';

import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';

class CarForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  onAutocompleteChange(field, country) {
    const newData = { ...this.props.formData };
    newData[field] = country;
    this.props.onChange(newData);
  }

  render() {
    return (
      <div className="box">
        <BoxTitle title="Offset Car Rental" onRemove={this.props.onRemove} />
        <div className="columns">
          <LabelledInput label="Vehicle type">
            <div className="select">
              <select
                value={this.props.formData.travel_class}
                onChange={(e) => this.onInputChange('car_type', e)}
              >
                <option value="" disabled>
                  - By Car Size -
                </option>
                <option value="AVERAGE_CAR">Average car</option>
                <option value="SMALL_CAR">Small car</option>
                <option value="MEDIUM_CAR">Medium car</option>
                <option value="LARGE_CAR">Large car</option>

                <option value="" disabled>
                  - By Market Segment -
                </option>
                <option value="MINI">Mini</option>
                <option value="SUPERMINI">Supermini</option>
                <option value="LOWER_MEDIUM">Lower medium</option>
                <option value="UPPER_MEDIUM">Upper medium</option>
                <option value="EXECUTIVE">Executive</option>
                <option value="LUXURY">Luxury</option>
                <option value="SPORTS">Sports</option>
                <option value="DUAL_PURPOSE_4X4">Dual Purpose 4x4</option>
                <option value="MPV">MPV</option>

                <option value="" disabled>
                  - By Motorcycle Size -
                </option>
                <option value="AVERAGE_MOTORBIKE">Average motorbike</option>
                <option value="SMALL_MOTORBIKE">Small motorbike</option>
                <option value="MEDIUM_MOTORBIKE">Medium motorbike</option>
                <option value="LARGE_MOTORBIKE">Large motorbike</option>
              </select>
            </div>
          </LabelledInput>
          <LabelledInput label="Distance">
            <div className="is-flex is-justify-content-space-between m-0">
              <div className="control has-icons-left is-fullwidth is-flex-grow-1">
                <input
                  className="input"
                  type="number"
                  min="1"
                  value={this.props.formData.distance}
                  onChange={(e) => this.onInputChange('distance', e)}
                  placeholder="Distance"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-car"></i>
                </span>
              </div>
              <div className="select select-measurement">
                <select
                  value={this.props.formData.travel_class}
                  onChange={(e) => this.onInputChange('measurement', e)}
                >
                  <option value="km" selected>
                    km
                  </option>
                  <option value="miles">miles</option>
                </select>
              </div>
            </div>
          </LabelledInput>
        </div>
      </div>
    );
  }
}

export default CarForm;
