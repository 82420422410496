import { Component, h } from 'preact';
import * as React from 'react';

import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';

class MeetingForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  onAutocompleteChange(field, country) {
    const newData = { ...this.props.formData };
    newData[field] = country;
    this.props.onChange(newData);
  }

  render() {
    return (
      <div className="box">
        <BoxTitle title="Offset a Meeting" onRemove={this.props.onRemove} />
        <div className="columns">
          <LabelledInput label="Duration (hours)">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                min="1"
                value={this.props.formData.hours}
                onChange={(e) => this.onInputChange('hours', e)}
                placeholder="Hours"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-clock"></i>
              </span>
            </div>
          </LabelledInput>
          <LabelledInput label="Meeting room size">
            <div className="control">
              <div className="select">
                <select
                  value={this.props.formData.travel_class}
                  onChange={(e) => this.onInputChange('size', e)}
                >
                  <option value="12">Small (3 people)</option>
                  <option value="24" selected>
                    Medium (8 people)
                  </option>
                  <option value="48">Large (8+ people)</option>
                </select>
              </div>
            </div>
          </LabelledInput>
          <LabelledInput label="Laptops">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                min="0"
                value={this.props.formData.laptop_count}
                onChange={(e) => this.onInputChange('laptop_count', e)}
                placeholder="Laptops"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-laptop"></i>
              </span>
            </div>
          </LabelledInput>
        </div>
      </div>
    );
  }
}

export default MeetingForm;
