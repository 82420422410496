import { render, h } from 'preact';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import Shop from './Features/Shop/Index';

import './bootstrap';

const searchElem = document.getElementById('shop-search');
if (searchElem) {
  const root = createRoot(searchElem);

  // root.render(<Shop {...searchElem.dataset} />, searchElem);
  root.render(<Shop {...searchElem.dataset} />);
}
