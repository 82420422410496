import { Component, h } from 'preact';
import * as React from 'react';

import LabelledInput from './Components/LabelledInput';
import BoxTitle from './Components/BoxTitle';

class TaxiForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onInputChange(field, e) {
    const newData = { ...this.props.formData };
    newData[field] = e.target.value;
    this.props.onChange(newData);
  }

  onAutocompleteChange(field, country) {
    const newData = { ...this.props.formData };
    newData[field] = country;
    this.props.onChange(newData);
  }

  render() {
    return (
      <div className="box">
        <BoxTitle
          title="Offset a Taxi Journey"
          onRemove={this.props.onRemove}
        />
        <div className="columns">
          <LabelledInput label="Taxi type">
            <div className="select">
              <select
                value={this.props.formData.travel_class}
                onChange={(e) => this.onInputChange('taxi_type', e)}
              >
                <option value="TAXI">Taxi</option>
                <option value="LONDON_TAXI">London Taxi</option>
              </select>
            </div>
          </LabelledInput>
          <LabelledInput label="Distance">
            <div className="is-flex is-justify-content-space-between m-0">
              <div className="control has-icons-left is-fullwidth is-flex-grow-1">
                <input
                  className="input"
                  type="number"
                  min="1"
                  value={this.props.formData.distance}
                  onChange={(e) => this.onInputChange('distance', e)}
                  placeholder="Distance"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-car"></i>
                </span>
              </div>
              <div className="select select-measurement">
                <select
                  value={this.props.formData.travel_class}
                  onChange={(e) => this.onInputChange('measurement', e)}
                >
                  <option value="km" selected>
                    km
                  </option>
                  <option value="miles">miles</option>
                </select>
              </div>
            </div>
          </LabelledInput>
          <LabelledInput label="Passengers">
            <div className="control has-icons-left">
              <input
                className="input"
                type="number"
                value={this.props.formData.passengers}
                min="1"
                onChange={(e) => this.onInputChange('passengers', e)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user-friends"></i>
              </span>
            </div>
          </LabelledInput>
        </div>
      </div>
    );
  }
}

export default TaxiForm;
